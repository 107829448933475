import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/actions";
import { useHistory } from "react-router-dom";
import hoc from "../../hoc/PageContainer";
import styles from "./Warehouses.module.scss";
import server from "../../utils/axios/api";
import errorHandler from "../../utils/error-handler";
import Moment from "react-moment";

import PageGuard from "../../components/PageGuard/PageGuard";

import warehouseBuilding from "../../assets/img/warehouse_building.png";
import warehouseCar from "../../assets/img/warehouse_car.png";
import SearchBar from "../../components/SearchBar/SearchBar";

import NewWarehouseModal from "../../components/Modal/NewWarehouseModal/NewWarehouseModal";

import { PageHeader, Button, Row, Col, Card, Avatar, Table, Typography, Tabs, Skeleton } from "antd";
const { Meta } = Card;
const { Text } = Typography;
const { TabPane } = Tabs;

const pagePath = "warehouses";
const defaultPageSize = 50;

const searchDataProducts = {
  searchFields: [
    { key: "prdName", name: "Terméknév" },
    { key: "prdCode", name: "Termékkód" },
    { key: "prdNetAvgPrice", name: "Átlagár" },
    { key: "prdLastPrice", name: "Utolsó beszerzési ár" },
  ],
  get searchValues() {
    return Object.assign(
      {},
      ...this.searchFields.map((field) => {
        return {
          [field.key]: "",
        };
      })
    );
  },
};

const searchDataWarehouseTransfers = {
  searchFields: [
    { key: "product.prdName", name: "Terméknév" },
    { key: "product.prdCode", name: "Termékkód" },
    { key: "user.firstName", name: "Végrehajtó", type: "dropdown", list: "user", value: "firstName", text: "fullName" },
  ],
  lists: ["user"],
  get searchValues() {
    return Object.assign(
      {},
      ...this.searchFields.map((field) => {
        return {
          [field.key]: "",
        };
      })
    );
  },
};

const Warehouses = (props) => {
  const [pageDetails, setPageDetails] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseTransfers, setWarehouseTransfers] = useState([]);
  const [products, setProducts] = useState([]);
  const [modalVisibleNew, setModalVisibleNew] = useState(false);
  const [totalWarehouseTransfers, setTotalWarehouseTransfers] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [queryParamsProducts, setQueryParamsProducts] = useState({
    limit: defaultPageSize,
    offset: "",
    search: { ...searchDataProducts.searchValues },
  });
  const [queryParamsWarehouseTransfers, setQueryParamsWarehouseTransfers] = useState({
    limit: defaultPageSize,
    offset: "",
    search: { ...searchDataWarehouseTransfers.searchValues },
  });
  const [loadingProducts, setLoadingProducts] = useState({
    search: false,
  });
  const [loadingWarehouseTransfers, setLoadingWarehouseTransfers] = useState({
    search: false,
  });

  const history = useHistory();

  useEffect(() => {
    props.setLoading(true);
  }, []);

  useEffect(() => {
    setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
  }, [props.store.system.pageDetails]);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getWarehouseTransfers();
  }, [queryParamsWarehouseTransfers]);

  useEffect(() => {
    getProducts();
  }, [queryParamsProducts]);

  const getProducts = () => {
    server
      .get(
        `/list/product/include?limit=${queryParamsProducts.limit}&offset=${queryParamsProducts.offset}&search=${JSON.stringify(queryParamsProducts.search)}&fields=${JSON.stringify(
          searchDataProducts.searchFields
        )}`
      )
      .then((response) => {
        const filteredProducts = response.data.rows.filter((product) => product.prdStatus === 1 && (product.warehouses.length > 0 || product.purchaseOrders.length > 0));
        setProducts(filteredProducts);
        setTotalProducts(response.data.count);
        setLoadingProducts({ ...loadingProducts, data: false });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const getWarehouses = () => {
    server
      .get("/warehouse")
      .then((response) => {
        const filteredData = response.data.filter((item) => item.userId === props.store.auth.user.id);
        setWarehouses(props.store.auth.user.userRole.accessLevel > 29 ? filteredData : response.data);
        props.setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const getWarehouseTransfers = () => {
    server
      .get(
        `/warehouse-transfer?limit=${queryParamsWarehouseTransfers.limit}&offset=${queryParamsWarehouseTransfers.offset}&search=${JSON.stringify(
          queryParamsWarehouseTransfers.search
        )}&fields=${JSON.stringify(searchDataWarehouseTransfers.searchFields)}`
      )
      .then((response) => {
        const filteredData = response.data.rows.filter((item) => item.userId === props.store.auth.user.id);
        setWarehouseTransfers(props.store.auth.user.userRole.accessLevel > 29 ? filteredData : response.data.rows);
        setTotalWarehouseTransfers(response.data.count);
        setLoadingWarehouseTransfers({ ...loadingWarehouseTransfers, data: false });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const handlePaginationProducts = (page, pageSize) => {
    setQueryParamsProducts({
      ...queryParamsProducts,
      limit: pageSize,
      offset: page === 1 ? "" : (page - 1) * pageSize,
    });
  };

  const handlePaginationWarehouseTransfers = (page, pageSize) => {
    setQueryParamsWarehouseTransfers({
      ...queryParamsWarehouseTransfers,
      limit: pageSize,
      offset: page === 1 ? "" : (page - 1) * pageSize,
    });
  };

  const handleClick = (id) => {
    window.open("/warehouse/" + id);
  };

  const tableColumnsTransfers = [
    {
      title: "Kód",
      align: "center",
      render: (record) => <Text style={{ textTransform: "uppercase" }}>{record.transferCode}</Text>,
    },
    {
      title: "Raktár",
      render: (record) => <Text type={record.transferType === 1 ? "success" : "danger"}>{record.warehouse.wrhName}</Text>,
      sorter: (a, b) => {
        if (a.warehouse.wrhName < b.warehouse.wrhName) {
          return -1;
        }
        if (a.warehouse.wrhName > b.warehouse.wrhName) {
          return 1;
        }
      },
    },
    {
      title: "Termék kód",
      render: (record) => <Text>{record.product.prdCode}</Text>,
      sorter: (a, b) => {
        if (a.product.prdCode < b.product.prdCode) {
          return -1;
        }
        if (a.product.prdCode > b.product.prdCode) {
          return 1;
        }
      },
    },
    {
      title: "Termék",
      render: (record) => <Text>{record.product.prdName}</Text>,
      sorter: (a, b) => {
        if (a.product.prdName < b.product.prdName) {
          return -1;
        }
        if (a.product.prdName > b.product.prdName) {
          return 1;
        }
      },
    },
    {
      title: "Mennyiség",
      align: "center",
      render: (record) => (
        <Text type={record.transferType === 1 ? "success" : "danger"}>
          {record.transferType === 1 ? record.prdQty : "-" + record.prdQty} {record.product.prdQtyUnit}
        </Text>
      ),
    },
    {
      title: "Egységár",
      align: "center",
      render: (record) => <Text>{record.prdNetPrice === null ? "NR" : record.prdNetPrice.toLocaleString("hu") + " Ft"}</Text>,
      sorter: (a, b) => {
        if (a.product.prdName < b.product.prdName) {
          return -1;
        }
        if (a.product.prdName > b.product.prdName) {
          return 1;
        }
      },
    },
    {
      title: "Megrendelés",
      align: "center",
      render: (record) => <Text>{record.orderId || "NR"}</Text>,
    },
    {
      title: "Átlagár",
      align: "center",
      render: (record) => {
        if (props.store.auth.user.userRole.accessLevel <= 29) {
          return <Text>{record.prdNetAvgPrice === null ? "NR" : record.prdNetAvgPrice.toLocaleString("hu") + " Ft"}</Text>;
        } else {
          return <Text>-</Text>;
        }
      },
      sorter: (a, b) => {
        if (a.product.prdNetAvgPrice < b.product.prdNetAvgPrice) {
          return -1;
        }
        if (a.product.prdNetAvgPrice > b.product.prdNetAvgPrice) {
          return 1;
        }
      },
    },
    {
      title: "Összár",
      align: "center",
      render: (record) => <Text>{record.prdNetPrice === null ? 0 : (record.prdNetPrice * record.prdQty).toLocaleString("hu")} Ft</Text>,
      sorter: (a, b) => {
        if (a.product.prdNetAvgPrice < b.product.prdNetAvgPrice) {
          return -1;
        }
        if (a.product.prdNetAvgPrice > b.product.prdNetAvgPrice) {
          return 1;
        }
      },
    },
    {
      title: "Végrehajtó",
      render: (record) => <Text>{record.user.fullName}</Text>,
    },
    {
      title: "Időpont",
      render: (record) => (
        <Text type="secondary">
          <Moment format="YYYY/MM/DD - HH:mm" local>
            {record.createdAt}
          </Moment>
        </Text>
      ),
    },
  ];

  const tableColumnsItems = [
    {
      title: "Termék kód",
      render: (record) => <Text>{record.prdCode}</Text>,
      sorter: (a, b) => {
        if (a.prdCode < b.prdCode) {
          return -1;
        }
        if (a.prdCode > b.prdCode) {
          return 1;
        }
      },
    },
    {
      title: "Termék",
      render: (record) => <Text>{record.prdName}</Text>,
      sorter: (a, b) => {
        if (a.prdName < b.prdName) {
          return -1;
        }
        if (a.prdName > b.prdName) {
          return 1;
        }
      },
    },
    {
      title: "Készleten összesen",
      align: "center",
      render: (record) => {
        let currentAmount = record.warehouses?.filter((warehouse) => !warehouse.isWebshop).reduce((a, b) => a + b.warehouseItem.prdQty, 0);
        return currentAmount + " " + record.prdQtyUnit;
      },
    },
    {
      title: "Készleten (A)",
      align: "center",
      render: (record) => {
        let currentAmount = record.warehouses.filter((warehouse) => warehouse.id === 1).reduce((a, b) => a + b.warehouseItem.prdQty, 0); // ID: warehouse
        return currentAmount + " " + record.prdQtyUnit;
      },
    },
    {
      title: "Készleten (B)",
      align: "center",
      render: (record) => {
        let currentAmount = record.warehouses.filter((warehouse) => warehouse.id === 2).reduce((a, b) => a + b.warehouseItem.prdQty, 0); // ID: warehouse
        return currentAmount + " " + record.prdQtyUnit;
      },
    },
    {
      title: "Készleten (Webshop)",
      align: "center",
      render: (record) => {
        let currentAmount = record.warehouses.filter((warehouse) => warehouse.isWebshop).reduce((a, b) => a + b.warehouseItem.prdQty, 0); // ID: warehouse
        return currentAmount + " " + record.prdQtyUnit;
      },
    },
    {
      title: "Beszerzés alatt",
      align: "center",
      render: (record) => {
        let filteredPurchaseOrders = record.purchaseOrders.filter((purchaseOrder) => purchaseOrder.purchaseOrderStatus.key != 100);
        let currentAmount = filteredPurchaseOrders.reduce((a, b) => a + parseFloat(b.purchaseOrderLine.prcholnQty), 0);
        return currentAmount;
      },
      responsive: ["lg"],
    },
    {
      title: "Nyitott megrendelésben",
      align: "center",
      render: (record) => {
        if (props.store.auth.user.userRole.accessLevel <= 25) {
          return <Text>{`${record.prdOpenOrderQty === null ? 0 : record.prdOpenOrderQty} ${record.prdQtyUnit}`}</Text>;
        } else {
          return <Text>-</Text>;
        }
      },
      sorter: (a, b) => {
        if (a.prdOpenOrderQty < b.prdOpenOrderQty) {
          return -1;
        }
        if (a.prdOpenOrderQty > b.prdOpenOrderQty) {
          return 1;
        }
      },
      responsive: ["lg"],
    },
  ];

  return (
    <PageGuard path={pagePath} data={warehouses}>
      <div className={styles.Warehouses}>
        <NewWarehouseModal visible={modalVisibleNew} toggle={() => setModalVisibleNew(!modalVisibleNew)} store={props.store} getWarehouses={getWarehouses} />
        <PageHeader
          className={styles.pageHeader}
          onBack={() => window.history.back()}
          title={pageDetails.title}
          subTitle={pageDetails.subtitle}
          extra={[
            <Button key="3" onClick={() => history.push("/producttransfer")} disabled={props.store.auth.user.userRole.accessLevel > 29 ? true : false}>
              Árumozgatás
            </Button>,
            <Button key="1" type="primary" onClick={() => setModalVisibleNew(true)}>
              Új raktár
            </Button>,
          ]}
        />
        <div className={styles.pageBody}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                {warehouses.map((warehouse) => {
                  return (
                    <Col span={8} key={warehouse.id}>
                      <Card style={{ cursor: "pointer" }} onClick={() => handleClick(warehouse.id)}>
                        <Meta
                          avatar={<Avatar src={warehouse.wrhType === 1 ? warehouseBuilding : warehouseCar} size={64} />}
                          title={warehouse.wrhName}
                          description={warehouse.wrhType === 1 ? "Belső raktár" : warehouse.user ? warehouse.user.fullName : null}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Card bodyStyle={{ paddingTop: 0 }}>
                    <Tabs defaultActiveKey="1" size="large">
                      <TabPane tab={<span>Globális raktárkészlet</span>} key="1">
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <SearchBar
                              defaultPageSize={defaultPageSize}
                              queryParams={queryParamsProducts}
                              action={setQueryParamsProducts}
                              searchFields={searchDataProducts.searchFields}
                              loading={loadingProducts}
                              setLoading={setLoadingProducts}
                            />
                          </Col>
                          <Col span={24}>
                            <Table
                              dataSource={products}
                              columns={tableColumnsItems}
                              size="small"
                              pagination={{ onChange: (page, pageSize) => handlePaginationProducts(page, pageSize), total: totalProducts, defaultPageSize }}
                              loading={loadingProducts.data}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      {props.store.auth.user.userRole.accessLevel > 29 ? null : (
                        <TabPane tab={<span>Globális árumozgások</span>} key="2">
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <SearchBar
                                defaultPageSize={defaultPageSize}
                                queryParams={queryParamsWarehouseTransfers}
                                action={setQueryParamsWarehouseTransfers}
                                searchFields={searchDataWarehouseTransfers.searchFields}
                                lists={searchDataWarehouseTransfers.lists}
                                loading={loadingWarehouseTransfers}
                                setLoading={setLoadingWarehouseTransfers}
                              />
                            </Col>
                            <Col span={24}>
                              <Table
                                dataSource={warehouseTransfers}
                                columns={tableColumnsTransfers}
                                size="small"
                                pagination={{ onChange: (page, pageSize) => handlePaginationWarehouseTransfers(page, pageSize), total: totalWarehouseTransfers, defaultPageSize }}
                                loading={loadingWarehouseTransfers.data}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </PageGuard>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (params) => {
      dispatch(actionCreators.set_loading(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(hoc(Warehouses, pagePath));
